/** @jsx jsx */
import { useEffect } from "react"
import { jsx, Global } from "@emotion/core"
import { graphql, Link } from "gatsby"
import moment from "moment"
import { Layout } from "../components/Layout"
import { Box } from "theme-ui"
import { Styled, css } from "theme-ui"

// styled-system/css can access our theme values to override the form embed
const embedStylesheet = css({
  "#an-embed": {
    fontFamily: theme => theme.fonts.sans + "!important",
    background: "transparent !important",
    color: theme => theme.colors.text + "!important",
    /** The header for the form area- changing 'rsvp' to 'check in' */
    "#can_embed_form_inner > h4:nth-of-type(1)": {
      textTransform: "none !important",
      visibility: "hidden",
      color: "white",
      border: "none",
      margin: 0,
      padding: 2,
      "::after": {
        margin: 0,
        border: "none",
        visibility: "visible",
        content: "'Attendance'",
        fontSize: 4,
        color: theme => theme.colors.text + "!important",
        position: "absolute",
        top: "-10px",
        background: "transparent",
      },
    },
    /** The submit button text: changing 'rsvp' to 'check in' */
    "#form_col2": {
      "::after": {
        margin: 0,
        border: "none",
        visibility: "visible",
        content: "'Check In'",
        // color: "green",
        color: theme => theme.colors.white + "!important",
        position: "absolute",
        top: "0px",
        width: "100%",
        mt: 2,
        fontSize: 3,
        textAlign: "center",
        zIndex: 10,
        background: "transparent",
      },
    },
    /** The submit button -hiding text, changing color, rounding corners */
    "input[type=submit]": {
      height: "44px",
      textTransform: "none !important",
      background: theme => theme.colors.primary + "!important",
      color: "transparent !important",
      borderRadius: theme => theme.radii[3] + "px !important",
    },
    ".core_field *": {
      borderRadius: theme => theme.radii[3] + "px !important",
    },
  },
} as any)

export default ({ data }) => {
  // We need to set up the script in a hook and manually append it to the dom
  // because react doesn't like executing strangers' scripts
  useEffect(() => {
    const embed = document.getElementById("an-embed")

    if (embed) {
      const script = document
        .createRange()
        .createContextualFragment(data.actionNetworkEvent.fields.formEmbed)

      console.warn(script, embed)
      if (embed) embed.appendChild(script)
    }
  }, [])

  if (data.actionNetworkEvent.status !== "confirmed") {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Styled.h2>Event not found.</Styled.h2>
        <Link to="/">
          <Styled.h4 sx={{ color: "primary", mt: 1 }}>(Try again?)</Styled.h4>
        </Link>
      </Box>
    )
  } else if (
    moment(data.actionNetworkEvent.startDate).isBefore(moment(), "days")
  ) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Styled.h2>This event has passed.</Styled.h2>
        <Link to="/">
          <Styled.h4 sx={{ color: "primary", mt: 1 }}>(Try again?)</Styled.h4>
        </Link>
      </Box>
    )
  } else {
    return (
      <Layout title="Here">
        <Global styles={embedStylesheet} />
        <Box sx={{ my: 3 }} id="an-embed" />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query AcNetEmbed($slug: String!) {
    actionNetworkEvent(shortCode: { eq: $slug }) {
      startDate
      status
      title
      fields {
        formEmbed
      }
    }
  }
`
